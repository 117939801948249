module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/logonovo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"129acc74dd5d1cdd17434dc4c2add663"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MCSZ2V8","includeInDevelopment":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-cloudofertas/gatsby-browser.js'),
      options: {"plugins":[],"env":{"dev":{"siteId":"631ec06c-68bf-48fe-b832-4e29bc6a3b88","accessToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjQ0MTMxNWQ5LTFmNDMtNDc5Zi1iYzg1LTRhZmVlY2UzYzI1MCIsInVzZXIiOiJhYTBiYWE3MS0yM2UzLTQ2YTctYjY5ZC1kNDk4MzAzNmI4OGUiLCJzaXRlIjoiNjMxZWMwNmMtNjhiZi00OGZlLWI4MzItNGUyOWJjNmEzYjg4IiwiaWF0IjoxNjgyNDQ1MjAyfQ.U3rh3yxLc-HKx0x5hM63i5skPm9ldrXO9dZQOPIhZYo"},"prd":{"siteId":"631ec06c-68bf-48fe-b832-4e29bc6a3b88","accessToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjQ0MTMxNWQ5LTFmNDMtNDc5Zi1iYzg1LTRhZmVlY2UzYzI1MCIsInVzZXIiOiJhYTBiYWE3MS0yM2UzLTQ2YTctYjY5ZC1kNDk4MzAzNmI4OGUiLCJzaXRlIjoiNjMxZWMwNmMtNjhiZi00OGZlLWI4MzItNGUyOWJjNmEzYjg4IiwiaWF0IjoxNjgyNDQ1MjAyfQ.U3rh3yxLc-HKx0x5hM63i5skPm9ldrXO9dZQOPIhZYo"}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/codebuild/output/src1887205622/src/src/components/Layout/index.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
