import React, { Fragment } from 'react'
import PropTypes from 'prop-types';

/* MAIN SCSS */
import '../../scss/base.scss'

/* BOOTSTRAP */
import 'bootstrap/dist/css/bootstrap.min.css'

/* FONT */
import '../../Font/Poppins/Poppins.css'
import '../../Font/Montserrat/Montserrat.css'

const Layout = ({ children }) => {
  return (
    <Fragment >
      <main>
        {children}
      </main>
    </Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout
